/* Don't use module.exports.default.Provider,
 * use SettingsStore instead because it will format things nicely.
 *
 * To consume, use module.exports.default and module.exports.default.Consumer as normal.
 */
import React from "react";
import PropTypes from "prop-types";

import { linkPropTypes } from "src/atoms/Link";

import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import getImage from "src/utils/getImage";

const Context = React.createContext({});

export const SettingsStore = ({ value, children }) => {
  if (typeof value !== "object") {
    throw new Error(`Expecting Settings object, received "${value}"`);
  }

  const clean = {
    ...value,
    // make translation entries into a single key:value object
    translations: value.translations.reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {}
    ),
    headerLogo: getImage(value.headerLogo, PICTURE_TYPES.fixed),
    countryFlag: getImage(value.countryFlag, PICTURE_TYPES.fixed),
  };
  return <Context.Provider value={clean}>{children}</Context.Provider>;
};

export const SettingsShape = {
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  legalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      ...linkPropTypes,
    })
  ).isRequired,
  canonicalUrLs: PropTypes.arrayOf(PropTypes.string),
  popups: PropTypes.array,
};

SettingsStore.propTypes = {
  value: PropTypes.shape(SettingsShape).isRequired,
  children: PropTypes.node,
};

export default Context;
