import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { colors, mediaquery, spacing } from "src/styles/variables";
import { SubHeaderS, HeaderM } from "src/atoms/Typography";
import Link, { TYPES } from "src/atoms/Link";
import { DIMENSIONS } from "src/molecules/Card";

const TheGutLifeWelcome = React.memo(
  ({ hint, title, showCta, label, url, buttonStyle, ...rest }) => (
    <Outer {...rest}>
      <Hint>{hint}</Hint>
      <Title as="h1">{title}</Title>
      <div>
        {!!showCta && (
          <StyledLink
            label={label}
            type={TYPES.button}
            url={url}
            title={label}
            buttonStyle={buttonStyle}
          />
        )}
      </div>
    </Outer>
  )
);

TheGutLifeWelcome.propTypes = {
  hint: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  showCta: PropTypes.bool.isRequired,
  label: PropTypes.node,
  url: PropTypes.string,
  buttonStyle: PropTypes.string,
  className: PropTypes.string,
};

const Outer = styled.div`
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: visible;
  width: ${DIMENSIONS.width}px;
  height: ${DIMENSIONS.height}px;

  ${mediaquery.md(css`
    width: ${DIMENSIONS.width * 2 + DIMENSIONS.gutter}px;
  `)}
`;

const Hint = styled(SubHeaderS)`
  display: block;
  color: ${colors.activiaGreen};
  margin: ${spacing.stack.sm};
`;

const Title = styled(HeaderM)`
  display: block;
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.lg};
`;

const StyledLink = styled(Link)`
  width: 300px;
  ${mediaquery.md(css`
    width: auto;
  `)}
`;

export default TheGutLifeWelcome;
