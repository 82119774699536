import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ClipboardJS from "clipboard";

import Settings from "src/stores/Settings";

import { icons, spacing, colors } from "src/styles/variables";

import Facebook from "src/atoms/Vectors/Social/Facebook";
import Twitter from "src/atoms/Vectors/Social/Twitter";
import CopyUrl from "src/atoms/Vectors/Social/CopyUrl";

import Bubble, { ARROW_POSITION } from "src/atoms/Bubble";
import trackEvent from "./trackEvent";

const POPUP_SIZE = 570;

const Share = ({
  enableFacebook = true,
  enableTwitter = true,
  enableCopy = true,
  twitterCopy,
}) => {
  const [bubbleOpened, setBubbleOpened] = useState(false);
  const [documentURL, setDocumentURL] = useState("");
  const copyToClipboardButton = useRef(null);

  const socialWindow = (url) => {
    const left = (screen.width - POPUP_SIZE) / 2;
    const top = (screen.height - POPUP_SIZE) / 2;
    const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
    window.open(url, "NewWindow", params);
  };

  useEffect(() => {
    setDocumentURL(document.URL);
  }, []);

  const pageUrl = encodeURIComponent(documentURL);

  useEffect(() => {
    if (copyToClipboardButton.current) {
      const clipboard = new ClipboardJS(copyToClipboardButton.current);

      clipboard.on("success", () => {
        trackEvent("link");
        setBubbleOpened(true);
      });
    }
  }, []);

  return (
    <Settings.Consumer>
      {(settings) => (
        <ShareContainer>
          {!!enableFacebook && (
            <ShareItem>
              <Button
                onClick={() => {
                  trackEvent("facebook");
                  socialWindow(
                    `https://www.facebook.com/sharer.php?u=${pageUrl}`
                  );
                }}
              >
                <Facebook size={icons.s} fill={colors.white} />
              </Button>
            </ShareItem>
          )}
          {!!enableTwitter && (
            <ShareItem>
              <Button
                onClick={() => {
                  trackEvent("twitter");
                  socialWindow(
                    `https://twitter.com/intent/tweet?url=${pageUrl}&text=${
                      twitterCopy || ""
                    }`
                  );
                }}
              >
                <Twitter size={icons.s} fill={colors.white} />
              </Button>
            </ShareItem>
          )}
          {!!enableCopy && (
            <ShareItem style={{ position: "relative" }}>
              <StyledBubble
                onClose={() => setBubbleOpened(false)}
                isOpen={bubbleOpened}
                forcePosition={ARROW_POSITION.center}
              >
                {settings.translations.shareUrlPopup}
              </StyledBubble>
              <Button
                ref={copyToClipboardButton}
                data-clipboard-text={documentURL}
              >
                <CopyUrl stroke={colors.white} size={icons.xs} />
              </Button>
            </ShareItem>
          )}
        </ShareContainer>
      )}
    </Settings.Consumer>
  );
};

const ShareContainer = styled.ul`
  display: flex;
`;

const ShareItem = styled.li`
  margin-left: ${spacing.default.sm};
  background: ${colors.activiaGreen};
  border-radius: 50%;
  width: ${icons.m};
  height: ${icons.m};
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }
`;

const Button = styled.button`
  outline: none;
  cursor: pointer;
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const StyledBubble = styled(Bubble)`
  position: absolute;
  bottom: 50px;
  width: 260px;
  left: 50%;
  transform: translateX(-50%);
`;

Share.propTypes = {
  enableFacebook: PropTypes.bool,
  enableTwitter: PropTypes.bool,
  enableCopy: PropTypes.bool,
  twitterCopy: PropTypes.string,
};

export default Share;
