import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import PageContext from "src/stores/Page";

import { icons } from "src/styles/variables";
import HeaderSpace from "src/organisms/Header/Spacer";
import HeroSpace from "src/molecules/Hero/Spacer";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import Close from "src/atoms/Vectors/Standard/Close";
import mappers from "./mappers";

const Modules = ({ modules, onClose, pageType }) => {
  const {
    visibleHeader: [isHeaderVisible],
  } = useContext(PageContext);

  const moduleWithContactEmail = modules.find(
    (module) =>
      module.__typename === "ContentfulTextModule" &&
      module.value.raw.includes('"uri":"mailto:')
  );

  useEffect(() => {
    if (moduleWithContactEmail) {
      const contactEmails = window.document.querySelectorAll(
        "a[href*='mailto:']"
      );
      for (const contactEmail of contactEmails) {
        contactEmail.addEventListener(
          "click",
          () => {
            if (typeof window.tC.event.contact_request_email === "function") {
              window.tC.event.contact_request_email(this, {});
            }
          },
          { once: true }
        );
      }
    }
  }, [moduleWithContactEmail]);

  return modules.map((componentProps, key) => {
    const child = mappers[componentProps.__typename];
    if (!child) {
      console.log(
        `Skipping unsupported page component "${
          componentProps.__typename
        }", expecting one of: ${Object.keys(mappers).join(", ")}`
      );
      return;
    }
    const { Component, mapper } = child;

    if (key === 0) {
      if (componentProps.__typename !== "ContentfulHero") {
        return (
          <HeaderSpace key={`${componentProps.__typename}-${key}`}>
            <Component {...mapper(componentProps)} />
            {onClose && <CloseButton onClick={onClose} />}
          </HeaderSpace>
        );
      }

      return (
        <React.Fragment key={`${componentProps.__typename}-${key}`}>
          <Component {...mapper(componentProps)} />
          {onClose && (
            <CloseButton onClick={onClose} isHeaderVisible={isHeaderVisible} />
          )}
        </React.Fragment>
      );
    }
    if (
      componentProps.__typename === "ContentfulTheGutLifePreview" &&
      modules[Math.max(0, key - 1)].__typename === "ContentfulHero"
    ) {
      return (
        <HeroSpace key={key}>
          <Component {...mapper(componentProps)} />
        </HeroSpace>
      );
    }

    return (
      <Component
        key={`${componentProps.__typename}-${key}`}
        {...mapper(componentProps)}
        pageType={pageType}
      />
    );
  });
};

const CloseButton = ({ onClick, isHeaderVisible }) => {
  return (
    <StickyContainer isHeaderVisible={isHeaderVisible}>
      <Row>
        <Col xs={8} md={14}>
          <CloseLinkOuter>
            <a onClick={onClick}>
              <FloatingButton size={SIZES.medium}>
                <Close size={icons.m} />
              </FloatingButton>
            </a>
          </CloseLinkOuter>
        </Col>
      </Row>
    </StickyContainer>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isHeaderVisible: PropTypes.bool,
};

const StickyContainer = styled(Container)`
  position: sticky;
  top: ${({ isHeaderVisible }) => (isHeaderVisible ? "100px" : "50px")};
  z-index: 1;
`;

const CloseLinkOuter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
};

export default Modules;
