import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { colors, icons, spacing } from "src/styles/variables";
import Close from "src/atoms/Vectors/Standard/Close";
import getViewport from "src/utils/viewport";
import debounce from "src/utils/debounce";

export const ARROW_POSITION = Object.freeze({
  left: "left",
  center: "center",
  right: "right",
});

const Bubble = ({
  isOpen = false,
  onClose,
  children,
  forcePosition,
  ...props
}) => {
  const [position, setPosition] = useState(null);
  const el = useRef(null);

  const handleClose = () => {
    onClose();
  };

  const getTooltipPosition = () => {
    if (el && el.current) {
      const rect = el.current.getBoundingClientRect();
      if (!rect) {
        return false;
      }
      const viewport = getViewport();
      const breakpoint = Math.floor(viewport.width / 3);
      const distanceFromLeft = Math.floor(rect.left);
      const distanceFromRight = Math.floor(viewport.width - rect.right);

      if (
        distanceFromRight < distanceFromLeft &&
        distanceFromRight < breakpoint
      ) {
        setPosition(ARROW_POSITION.right);
      } else if (
        distanceFromLeft < distanceFromRight &&
        distanceFromLeft < breakpoint
      ) {
        setPosition(ARROW_POSITION.left);
      } else {
        setPosition(ARROW_POSITION.center);
      }
    }
  };

  const debouncedResize = debounce(getTooltipPosition, 50);

  if (!forcePosition) {
    useEffect(() => {
      getTooltipPosition();
      window.addEventListener("resize", debouncedResize);

      return () => {
        window.removeEventListener("resize", debouncedResize);
      };
    });
  } else {
    useEffect(() => {
      setPosition(forcePosition);
    });
  }

  return (
    <Outer isOpen={isOpen} ref={el} {...props}>
      <Inner position={position} isOpen={isOpen}>
        {onClose && (
          <CloseButton onClick={handleClose}>
            <Close size={icons.s} />
          </CloseButton>
        )}
        {children}
      </Inner>
    </Outer>
  );
};

Bubble.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  forcePosition: PropTypes.oneOf(Object.values(ARROW_POSITION)),
};

const Outer = styled.div`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};
  transition: opacity 0.3s cubic-bezier(0.58, 0, 0.56, 1);
  position: relative;
`;

const Inner = styled.div`
  padding: ${spacing.default.lg};
  background: ${colors.white};
  box-shadow: 0 4px 10px ${colors.translucidBlack};
  border-radius: 16px;
  position: relative;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    border: 8px solid;
    border-color: white transparent transparent;
    top: 99%;

    ${({ position }) => {
      switch (position) {
        case ARROW_POSITION.left:
          return css`
            left: ${spacing.default.lg};
          `;
        case ARROW_POSITION.right:
          return css`
            right: ${spacing.default.lg};
          `;
        case ARROW_POSITION.center:
          return css`
            left: 50%;
            transform: translateX(-50%);
          `;
        default:
          return css`
            display: none;
          `;
      }
    }}
  }
`;

const CloseButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  position: absolute;
  top: ${spacing.default.xs};
  right: ${spacing.default.xs};
`;

export default Bubble;
