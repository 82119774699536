import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { colors } from "src/styles/variables";
import {
  LabelXS,
  LabelM,
  SubHeaderM,
  FactLegalMention,
} from "src/atoms/Typography";
import FactIcon from "src/atoms/Vectors/Card/Fact";
import FoldArrow from "src/atoms/Vectors/Card/FoldArrow";
import { animations } from "src/atoms/Illustration";

const BUTTON_SIZE = 50;
const ANIMATION_DURATION = 180;
const ANIMATION_CURVE = "cubic-bezier(0.47, 0, 0.37, 1)";

const CardFact = ({
  category,
  front,
  back,
  illustration,
  disclaimer,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const Animation = animations[illustration];
  const showAnimation =
    back.length <= 70 || (back.length <= 100 && !disclaimer);

  return (
    <Outer
      {...rest}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <Back show={open}>
        {showAnimation && (
          <AnimationOuter>
            <Animation play={open} position={open ? 0 : 1} />
          </AnimationOuter>
        )}
        <FactOuter isFull={!showAnimation}>
          <Fact as="p">{back}</Fact>
          {!!disclaimer && <Disclaimer as="p">{disclaimer}</Disclaimer>}
        </FactOuter>
        <BackArrow size="1em" />
      </Back>
      <Front show={!open}>
        <Category>
          <FactIcon size="2em" />
          <span>{category}</span>
        </Category>
        <Title as="h2">{front}</Title>
      </Front>
      <ToggleOuter show={open}>
        <FoldArrow size="1em" />
      </ToggleOuter>
    </Outer>
  );
};

CardFact.propTypes = {
  category: PropTypes.node.isRequired,
  front: PropTypes.node.isRequired,
  back: PropTypes.node.isRequired,
  illustration: PropTypes.oneOf(Object.keys(animations)),
  disclaimer: PropTypes.string,
};

const BackArrow = styled(FoldArrow)`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Outer = styled(Layer)`
  cursor: pointer;
`;
const Front = styled(Layer)`
  opacity: ${({ show }) => (show ? 1 : 0)};

  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  ${({ show }) =>
    show
      ? css`
          transition: opacity 0s ${ANIMATION_CURVE} 0s;
        `
      : css`
          transition: opacity 0s ${ANIMATION_CURVE} ${ANIMATION_DURATION}ms;
        `}
`;

const Category = styled(LabelXS)`
  display: flex;
  align-items: center;
  color: ${colors.activiaGreen};
`;

const Title = styled(SubHeaderM)`
  color: ${colors.black};
`;

const Back = styled(Layer)`
  background: ${colors.activiaGreen};
  border-radius: 16px;
  overflow: hidden;

  ${({ show }) =>
    show
      ? css`
          transition: opacity ${ANIMATION_DURATION}ms ${ANIMATION_CURVE}
            ${ANIMATION_DURATION}ms;
        `
      : css`
          transition: opacity ${ANIMATION_DURATION}ms ${ANIMATION_CURVE} 0s;
        `}
`;

const AnimationOuter = styled(Layer)`
  svg path {
    stroke: ${colors.white};
  }
`;
const FactOuter = styled(Layer)`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ isFull }) => (isFull ? "center" : "flex-end")};
  height: 100%;
  flex-direction: column;
  padding: 20px;
`;

const Fact = styled(LabelM)`
  color: ${colors.white};
  background: linear-gradient(
    to top,
    ${colors.activiaGreenGradient[0]} 50%,
    ${colors.activiaGreenGradient[1]} 100%
  );
`;

const Disclaimer = styled(FactLegalMention)`
  color: ${colors.white};
  margin-top: 5px;
`;

const ToggleOuter = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: ${({ show }) => (show ? "100%" : `${BUTTON_SIZE}px`)};
  width: ${({ show }) => (show ? "100%" : `${BUTTON_SIZE}px`)};
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: ${colors.activiaGreen};
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 8px;
  opacity: ${({ show }) => (show ? 0 : 1)};

  ${({ show }) =>
    show
      ? css`
          transition: width ${ANIMATION_DURATION}ms ${ANIMATION_CURVE},
            height ${ANIMATION_DURATION}ms ${ANIMATION_CURVE},
            opacity 0s ${ANIMATION_CURVE} ${ANIMATION_DURATION}ms;
        `
      : css`
          transition: width ${ANIMATION_DURATION}ms ${ANIMATION_CURVE},
            height ${ANIMATION_DURATION}ms ${ANIMATION_CURVE},
            opacity 0s ${ANIMATION_CURVE} 0s;
        `}

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    background: ${colors.lightGrey};
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(90deg);
    border-radius: 0 0 0 16px;
    opacity: ${({ show }) => (show ? 0 : 1)};
    transition: opacity ${ANIMATION_DURATION}ms ${ANIMATION_CURVE};
  }

  ${Outer}:hover & {
    height: ${({ show }) => (show ? "100%" : "55px")};
    width: ${({ show }) => (show ? "100%" : "55px")};
  }
`;

export default CardFact;
