import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { spacing, mediaquery } from "src/styles/variables";
import { LabelL, LabelS } from "src/atoms/Typography";

const NumericFact = React.memo(({ label, value, align, ...rest }) => (
  <Outer align={align}>
    <Circle>{value}</Circle>
    <LabelStyled>{label}</LabelStyled>
  </Outer>
));

NumericFact.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  align: PropTypes.string,
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20%;
  margin: ${({ theme }) => theme.numericFact.margin};
  ${({ align }) =>
    align === "left" &&
    css`
      margin-right: ${spacing.default.sm};
    `}
  max-width: 20%;

  ${mediaquery.lg(css`
    max-width: 25%;
  `)}

  ${mediaquery.xl(css`
    max-width: 20%;
  `)}
`;

const Circle = styled(LabelL)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.numericFact.circleBorderColor};
  border-radius: 100%;
  width: 70px;
  height: 70px;
  margin: 0;
  color: ${({ theme }) => theme.numericFact.circleTextColor};
  padding: 3px;

  white-space: normal;
`;

const LabelStyled = styled(LabelS)`
  margin-top: 5px;
  color: ${({ theme }) => theme.numericFact.labelColor};
`;

export default NumericFact;
