import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Visible } from "react-awesome-styled-grid";

import Picture from "src/atoms/Picture";
import { colors, spacing } from "src/styles/variables";
import { HeaderM, HeaderS } from "src/atoms/Typography";
import RichText from "src/atoms/RichText";
import Link, { TYPES } from "src/atoms/Link";

const Wrapper = styled.div`
  position: relative;
`;

const StyledPicture = styled(Picture)`
  border-radius: 12px;
  box-shadow: 0 6px 10px 2px ${colors.translucidBlack};
  width: 100%;

  & > div {
    display: none;
  }

  picture img {
    position: static !important;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: ${spacing.default.xl};
`;

const Cta = styled(Link)`
  margin-top: ${spacing.default.lg};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
`;

const ImagePush = ({ image, imageMobile, title, cta, url, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Inner as={url && url.length ? Link : "div"} url={url}>
        <StyledPicture small={imageMobile} large={image} />
        {(title || cta) && (
          <TextContainer>
            {title && (
              <>
                <Visible xs sm>
                  <RichText as={HeaderS} doc={title} />
                </Visible>
                <Visible md lg xl>
                  <RichText as={HeaderM} doc={title} />
                </Visible>
              </>
            )}
            {cta && <Cta {...cta} type={TYPES.button} />}
          </TextContainer>
        )}
      </Inner>
    </Wrapper>
  );
};

ImagePush.propTypes = {
  image: PropTypes.object.isRequired,
  imageMobile: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  cta: PropTypes.instanceOf(Object),
  url: PropTypes.string,
};

export default ImagePush;
