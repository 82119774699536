import React, {
  createRef,
  useRef,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
// import { Visible } from "react-awesome-styled-grid";
import debounce from "src/utils/debounce";
// import { colors, mediaquery, icons } from "src/styles/variables";
// import { Container, Row, Col } from "react-awesome-styled-grid";
// import { InView } from "react-intersection-observer";
// import InstagramFeed from "react-ig-feed";
import "react-ig-feed/dist/index.css";
import InstagramPost, { DIMENSIONS } from "./InstagramPost";
// import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server";
// import Picture, { FITS as PICTURE_FITS } from "src/atoms/Picture";
// import Settings from "src/stores/Settings";
// import { Detail } from "src/atoms/Typography";

import { colors, spacing, icons } from "src/styles/variables";
import { SubHeaderM } from "src/atoms/Typography";
import TouchProvider from "src/stores/Touch";
import FloatingButton from "src/molecules/FloatingButton";
import ArrowLeft from "src/atoms/Vectors/Arrows/ArrowLeft";
import ArrowRight from "src/atoms/Vectors/Arrows/ArrowRight";
import Dots from "src/atoms/Dots";

const ANIMATION_DURATION = 750;
// const LANDSCAPE = (9 / 16) * 100;
// const PORTRAIT = (16 / 9) * 100;

const InstagramImagesCarousel = ({
  title,
  autoplay,
  autoplayTransitionTime,
  instagramPosts,
}) => {
  // console.log("title", title);
  // console.log("autoplay", autoplay);
  // console.log("instagramPosts", instagramPosts);

  const isTouch = useContext(TouchProvider);
  const [ready, setReady] = useState(false);
  const [leftPadding, setLeftPadding] = useState(0);
  const [rightPadding, setRightPadding] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [needsArrows, setNeedsArrows] = useState(true);
  const [[showLeftArrow, showRightArrow], setShowArrows] = useState([
    false,
    true,
  ]);
  // const [setIsLargeScreen] = useState(false);
  const position = createRef();
  const outer = createRef();
  const inner = createRef();

  const selectedKey = useRef(0);
  const isAutoplay = useRef(autoplay);
  var autoplayInterval = useRef();

  const doChecks = () => {
    if (
      !outer.current ||
      !outer.current.offsetParent ||
      !position.current ||
      !inner.current
    ) {
      return;
    }

    const {
      left: parentLeft,
      width: parentWidth,
    } = outer.current.offsetParent.getBoundingClientRect();
    const {
      left: positionLeft,
      width: positionWidth,
    } = position.current.getBoundingClientRect();

    const leftPadding = positionLeft - parentLeft;
    const rightPadding =
      parentLeft + parentWidth - (positionLeft + positionWidth);

    const { width: outerWidth } = outer.current.getBoundingClientRect();
    const { width: innerWidth } = inner.current.getBoundingClientRect();

    setLeftPadding(leftPadding);
    setRightPadding(rightPadding);
    setNeedsArrows(outerWidth < innerWidth);
    // setIsLargeScreen(parentWidth >= 1400);
    setReady(true);
  };

  const stopAutoplay = () => {
    if (isAutoplay.current && needsArrows) {
      clearInterval(autoplayInterval);
    }
  };

  const startAutoplay = () => {
    if (isAutoplay.current && needsArrows) {
      autoplayInterval = setInterval(() => {
        initAutoplay();
      }, autoplayTransitionTime);
    }
  };

  useEffect(() => {
    doChecks();
    const debouncedDoChecks = debounce(doChecks, 250);
    window.addEventListener("resize", debouncedDoChecks, false);
    const script = document.createElement("script");

    script.src = "//www.instagram.com/embed.js";
    script.async = true;

    document.body.appendChild(script);
    //console.log("isAutoplay", isAutoplay);
    startAutoplay();
    return () => {
      window.removeEventListener("resize", debouncedDoChecks, false);
      document.body.removeChild(script);
      stopAutoplay();
    };
  });

  const getPositionLeft = () => {
    const { left } = position.current.getBoundingClientRect();
    return left;
  };
  const getLeftPad = () => {
    const absoluteLeft = getPositionLeft();
    const {
      left: parentLeft,
    } = outer.current.offsetParent.getBoundingClientRect();

    return absoluteLeft - parentLeft;
  };

  const scrollTo = (x, constrain = true) => {
    const min = 0;
    const max = inner.current.clientWidth - outer.current.clientWidth;
    const constrained = constrain ? Math.min(Math.max(0, x), max) : x;
    setScrollPosition(constrained);
    setShowArrows([min < constrained, constrained < max]);
  };

  const getItemElements = () => {
    const children = (inner.current || {}).children || [];
    return Reflect.apply(Array.prototype.slice, children, []);
  };

  const getIndexOfItemClosestToLeft = () => {
    const leftPad = getPositionLeft();
    const els = getItemElements();
    const sortedEls = els // sorted by closest to left hand side (ones that are beyond left will have negative vals)
      .map((el) => {
        const { x } = el.getBoundingClientRect();
        return {
          el,
          x: Math.abs(x - leftPad),
        };
      })
      .sort((a, b) => (a.x > b.x ? 1 : -1));
    const currentEl = sortedEls[0]["el"];
    const currentElKey = els.indexOf(currentEl);
    return currentElKey;
  };

  const slideToKey = (key) => {
    const els = getItemElements();
    const nextEl = els[key];
    const leftPad = getLeftPad();
    const nextOffsetLeft = key === 0 ? 0 : nextEl.offsetLeft - leftPad;
    scrollTo(nextOffsetLeft);
    selectedKey.current = key;
    //console.log("selectedKey.current", selectedKey.current);
  };

  const slide = (direction) => {
    const els = getItemElements();
    const currentElKey = getIndexOfItemClosestToLeft();
    const nextElKey = Math.max(
      0,
      Math.min(els.length - 1, currentElKey + direction)
    );
    slideToKey(nextElKey);
    selectedKey.current = nextElKey;
    //console.log("selectedKey.current", selectedKey.current);
  };

  const initAutoplay = () => {
    //console.log('showRightArrow',showRightArrow);
    if (!showRightArrow) {
      selectedKey.current = 0;
    } else {
      selectedKey.current++;
    }
    //console.log("selectedkey", selectedKey.current);
    slideToKey(selectedKey.current);
  };

  return (
    <>
      <InstagramFeedContainer>
        {/* <h1>Show Instagram Feed on your Website</h1> */}
        {/* <div id="instafeed-container"></div> */}
        {/* <InstagramFeed token={instaAcessToken} counter="5" />
        <div>Is static carousel = {autoplay.toString()}</div>
        <h2>instagram embeds</h2> */}
      </InstagramFeedContainer>
      {!!title && <Title>{title}</Title>}
      <Position ref={position}>
        <Outer
          ref={outer}
          ready={ready}
          isTouch={isTouch}
          onMouseOver={() => stopAutoplay()}
          onMouseLeave={() => startAutoplay()}
        >
          <Inner
            ref={inner}
            isTouch={isTouch}
            padding={[leftPadding, rightPadding]}
            scrollPosition={scrollPosition}
            needsArrows={needsArrows}
          >
            {instagramPosts.map((postURL, key) => (
              <Item key={key}>
                <InstagramPost
                  url={postURL + "?utm_source=ig_embed&utm_campaign=loading"}
                />
              </Item>
            ))}
          </Inner>
          {!isTouch && (
            <>
              {!isTouch && !!needsArrows && !!showLeftArrow && (
                <LeftContainer left={leftPadding}>
                  <FloatingButton>
                    <ArrowLeft size={icons.m} onClick={() => slide(-1)} />
                  </FloatingButton>
                </LeftContainer>
              )}
              {!isTouch && !!needsArrows && !!showRightArrow && (
                <RightContainer right={rightPadding}>
                  <FloatingButton>
                    <ArrowRight size={icons.m} onClick={() => slide(+1)} />
                  </FloatingButton>
                </RightContainer>
              )}
            </>
          )}
        </Outer>
      </Position>
      {!isTouch && !!needsArrows && (
        <DotsStyled
          items={instagramPosts.map((postURL, key) => ({
            active: false,
            label: postURL,
          }))}
          onSelect={(postURL, key) => slideToKey(key)}
        />
      )}
    </>
  );
};

InstagramImagesCarousel.propTypes = {
  title: PropTypes.string,
  autoplay: PropTypes.bool,
  autoplayTransitionTime: PropTypes.number,
  instagramPosts: PropTypes.array,
};

const InstagramFeedContainer = styled.section`
  position: relative;
`;

const Title = styled(SubHeaderM)`
  display: block;
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.lg};
`;

const Position = styled.div`
  position: static; /* important! this is used to determine where the alignment points are */
  width: 100%;
  height: ${DIMENSIONS.height + DIMENSIONS.gutter}px;
  margin: ${spacing.stack.md};
`;

const Outer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  overflow: ${({ isTouch }) => (isTouch ? "scroll" : "hidden")};

  width: 100%;
  height: ${DIMENSIONS.height + DIMENSIONS.gutter}px;
  padding: ${DIMENSIONS.gutter / 2}px 0;

  opacity: ${({ ready }) => (ready ? 1 : 0)};
`;
const ArrowContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
`;
const LeftContainer = styled(ArrowContainer)`
  left: ${({ left }) => left}px;
`;
const RightContainer = styled(ArrowContainer)`
  right: ${({ right }) => right}px;
`;
const Inner = styled.div`
  position: absolute;
  z-index: 0;
  display: flex;
  padding: ${({ padding: [paddingLeft, paddingRight] }) =>
    `0 ${paddingRight}px 0 ${paddingLeft}px`};

  ${({ isTouch }) =>
    !isTouch &&
    css`
      transform: translateX(-${({ scrollPosition }) => scrollPosition}px);
      transition: transform ${ANIMATION_DURATION / 1000}s;
    `}

  ${({ needsArrows }) =>
    !needsArrows &&
    css`
      left: 50%;
      transform: translateX(-50%);
      transition: none;
    `};
`;
const Item = styled.div`
  margin: 0 ${DIMENSIONS.gutter / 2}px;
`;
const DotsStyled = styled(Dots)`
  display: flex;
  justify-content: center;
`;

export default InstagramImagesCarousel;
